import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faX, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useRoute } from 'wouter';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../styles/LoteDetails.css';
import FramerButton from './FramerButton';
import BackButton from './BackButton';
import AgregarIncidenciaForm from './AgregarIncidenciaForm';
import Modal from './Modal';
import ProtectedContent from './ProtectedContent';


alertify.set('notifier', 'position', 'top-right');

const LoteDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [lote, setLote] = useState(null);
  const [historialIncidencias, setHistorialIncidencias] = useState([]);
  const [, params] = useRoute("/:constructoraId/fraccionamientos/:fraccionamientoId/:loteId");
  const [selectedEstado, setSelectedEstado] = useState('');
  const [historialCargado, setHistorialCargado] = useState(false);
  const [showImagenModal, setShowImagenModal] = useState(false);
  const [imagenModalUrl, setImagenModalUrl] = useState('');
  const [isModdalOpen2, setIsModdalOpen2] = useState(false);
  const correctPassword = '1028807';
  
  const estadoColores = {
    Listo: 'green',
    proceso: 'blue',
    Incidencia: 'red',
  };


  const openModal2 = (image) => {
    setSelectedImage(image);
    setIsModdalOpen2(true);
  };

  const closeModal2 = () => {
    setSelectedImage('');
    setIsModdalOpen2(false);
  };


  useEffect(() => {
    if (!params.loteId) return;

    // Obtener datos del lote
    fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error al obtener el lote');
            }
            return response.json();
        })
        .then((data) => {
            setLote(data);
            setSelectedEstado(data.estado);
        })
        .catch((error) => {
            console.error(error);
            alertify.error('Error al obtener el lote');
        });

    // Solo cargar historial si no ha sido cargado
    if (!historialCargado) {
        // Obtener incidencias filtradas por loteId directamente
        fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}/incidencias/lote/${params.loteId}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error al obtener incidencias');
                }
                return response.json();
            })
            .then((data) => {
                setHistorialIncidencias(data); // Ya son solo las incidencias del loteId
                setHistorialCargado(true);
            })
            .catch((error) => {
                console.error(error);
                alertify.error('Error al obtener el historial de incidencias');
            });
    }
}, [params, historialCargado]);




const handleEditarLote = () => {
  const camposEditables = ['openings', 'vinil', 'basements', 'color'];
  editarCamposRecursivamente(camposEditables, 0);
};

const editarCamposRecursivamente = async (campos, index) => {
  if (index >= campos.length) {
    return;
  }

  const campo = campos[index];

  if (campo === 'openings') {
    const propiedadesOpenings = ['frente', 'derecha', 'izquierda', 'atras'];
    await editarPropiedadesOpenings(propiedadesOpenings, 0);
  } else {
    alertify.prompt(`Editar ${campo}:`, lote[campo] || '', async (e, nuevoValor) => {
      if (e) {
        const valorNumerico = parseFloat(nuevoValor);
        let valorFormateado;
        if (Number.isInteger(valorNumerico)) {
          valorFormateado = valorNumerico.toString(); // Solo el número entero
        } else {
          valorFormateado = valorNumerico.toFixed(1); // Un decimal
        }
        await mostrarModalEditarLote({ [campo]: valorFormateado });
      }
      editarCamposRecursivamente(campos, index + 1);
    });
  }
};

const editarPropiedadesOpenings = async (propiedades, index) => {
  if (index >= propiedades.length) {
    return;
  }

  const propiedad = propiedades[index];

  alertify.prompt(`Editar ${propiedad}:`, lote.openings[propiedad] || '', async (e, nuevoValor) => {
    if (e) {
      const valorNumerico = parseFloat(nuevoValor);
      let valorFormateado;
      if (Number.isInteger(valorNumerico)) {
        valorFormateado = valorNumerico.toString(); // Solo el número entero
      } else {
        valorFormateado = valorNumerico.toFixed(1); // Un decimal
      }
      const updatedOpenings = { ...lote.openings, [propiedad]: valorFormateado };
      await mostrarModalEditarLote({ openings: updatedOpenings });
    }
    editarPropiedadesOpenings(propiedades, index + 1);
  });
};

const mostrarModalEditarLote = async (camposEditados) => {
  try {
    const response = await fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(camposEditados),
    });
    
    if (response.ok) {
      setLote((prevLote) => ({ ...prevLote, ...camposEditados }));
      alertify.success('Lote editado exitosamente');
    } else {
      alertify.error('Error al editar el lote');
    }
  } catch (error) {
    alertify.error('Error al editar el lote');
  }
};



  const formatFecha = (fechaString) => {
    const fecha = new Date(fechaString);
    return `${fecha.toLocaleDateString()} ${fecha.toLocaleTimeString()}`;
  };

  const iconColor = estadoColores[selectedEstado];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleIncidenciaAgregada = () => {
    alertify.success('Incidencia agregada exitosamente');
    // Aquí puedes volver a cargar el historial de incidencias si es necesario
    fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}/incidencias`)
      .then((response) => response.json())
      .then((data) => {
        setHistorialIncidencias(data);
      });
  };

  return (
    <div className="container mt-4 page">
      {lote ? (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="bg-gray-400 border-b border-gray-300 py-4 px-6">
            <BackButton destination={`/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}`} />
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-white">Detalles del Lote</h2>
              <FramerButton
                className="bg-yellow-500 shadow-md shadow-yellow-600 hover:shadow-yellow-600/30 text-white hover:bg-yellow-600 py-2 px-4 rounded-lg inline-flex items-center"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2"         
 />
                Add
              </FramerButton>
              <FontAwesomeIcon icon={faHome} size="lg" className={`text-${iconColor}-500`} />
              <ProtectedContent correctPassword={correctPassword}>
        <FramerButton
          className="bg-yellow-500 shadow-md shadow-yellow-600 hover:shadow-yellow-600/30 text-white hover:bg-yellow-600 py-2 px-4 rounded-lg inline-flex items-center"
          onClick={() => handleEditarLote(true)}
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Editar
        </FramerButton>
      </ProtectedContent>
            </div>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <p className="text-gray-600 font-medium">Número de Lote</p>
                <p>{lote.numero_lote}</p>
              </div>
              <div className="mb-4">
      <p className="text-gray-800 font-semibold text-lg mb-2">Openings</p>
      {typeof lote.openings === 'object' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {['frente', 'derecha', 'izquierda', 'atras'].map((direction) => (
            <div key={direction} className="bg-white p-4 rounded shadow">
              <p className="font-medium text-gray-700">
                {`${direction.charAt(0).toUpperCase() + direction.slice(1)}: ${lote.openings[direction] || '0.00'}`}
                </p>
              <img
                src={`https://back.ivdian.ca/${lote.imagenes[direction]}`}
                alt={direction}
                className="w-full h-auto rounded-lg shadow-md mb-2 cursor-pointer"
                onClick={() => openModal2(`https://back.ivdian.ca/${lote.imagenes[direction]}`)}
              />
            </div>
          ))}
          <div className="col-span-1 md:col-span-2">
            <p className="font-semibold text-gray-800">
              Total: {Object.values(lote.openings).reduce((sum, value) => sum + (parseFloat(value) || 0), 0)}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-red-500">{lote.openings}</p>
      )}
      <Modal isOpen={isModdalOpen2} onClose={closeModal2}>
        {selectedImage && <img src={selectedImage} alt="Zoomed" className="w-full h-auto max-h-screen" />}
      </Modal>
    </div>

              <div>
                <p className="text-gray-600 font-medium">Vinil</p>
                <p>{lote.vinil}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Basement</p>
                <p>{lote.basement}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Color</p>
                <p>{lote.color}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Estado</p>
                <p style={{ color: iconColor }}>{selectedEstado}</p>
              </div>
            </div>
            <div className="mt-6">
  <h3 className="text-lg font-semibold mb-4">Historial</h3>
  {historialIncidencias.length > 0 ? (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {historialIncidencias.map((incidencia) => (
        <div key={incidencia.id} className="bg-white rounded-lg shadow-md p-4 transition-transform transform hover:scale-105">
          <h4 className="text-md font-semibold mb-2">{incidencia.descripcion}</h4>
          <span className="block text-sm text-gray-600 mb-2">Fecha: {formatFecha(incidencia.fecha)}</span>
          <img src={`http://back.ivdian.ca/${incidencia.imagen}`} alt={incidencia.titulo} className="w-full h-32 object-cover rounded-md mb-2" />
          <div className="mb-2">
            <span className="font-medium">Trabajador: </span>
            <span className="text-gray-700">{incidencia.trabajador}</span>
          </div>
          <div className="mb-2">
            <span className="font-medium">Openings: </span>
            <span className="text-gray-700">{incidencia.openings}</span>
          </div>
          <button
            onClick={() => {
              setImagenModalUrl("https://back.ivdian.ca/" + incidencia.imagen);
              setShowImagenModal(true);
            }}
            className="mt-2 bg-blue-500 text-white rounded-md py-1 px-3 hover:bg-blue-600 transition"
          >
            Ver imagen
          </button>
        </div>
      ))}
    </div>
  ) : (
    <p>No hay incidencias registradas.</p>
  )}
</div>
          </div>
          {/* Modal para mostrar imagen */}
          <Modal isOpen={showImagenModal} onClose={() => setShowImagenModal(false)}>
            {imagenModalUrl && <img src={imagenModalUrl} alt="Zoomed" className="w-full h-auto max-h-screen" />}
          </Modal>
        </div>
      ) : (
        <p>Cargando...</p>
      )}

 {/* Modal para agregar incidencia */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <AgregarIncidenciaForm
          constructoraId={params.constructoraId}
          fraccionamientoId={params.fraccionamientoId}
          loteId={params.loteId}
          onIncidenciaAgregada={handleIncidenciaAgregada}
        />
      </Modal>


      {/* Modal para ver imagen */}
      {showImagenModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowImagenModal(false)}>
              <FontAwesomeIcon icon={faX} />
            </span>
            <img src={imagenModalUrl} alt="Incidencia" className="img-fluid" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoteDetails;