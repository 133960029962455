import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const AgregarLoteComponent = ({ fraccionamientoId, constructoraId, onAddLote }) => {
  const [formData, setFormData] = useState({
    numero_lote: '',
    openings: {
      frente: '',
      derecha: '',
      izquierda: '',
      atras: '',
    },
    vinil: '',
    basements: '',
    color: '',
    estado: 'Listo', // Establece un valor predeterminado para el estado
    fraccionamiento: fraccionamientoId,
    constructoraId: constructoraId,
  });

  const [imagenes, setImagenes] = useState({
    frente: null,
    derecha: null,
    izquierda: null,
    atras: null,
  });

  // Estado para manejar el loading
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpeningsChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      openings: {
        ...formData.openings,
        [name]: value,
      },
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              setImagenes((prev) => ({
                ...prev,
                [name]: blob,
              }));
            },
            'image/jpeg',
            0.85
          );
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Activar el loading

    const formDataToSend = new FormData();

    formData.fraccionamiento = fraccionamientoId;
    formData.constructoraId = constructoraId;

    for (const key in formData) {
      if (key === 'openings') {
        for (const openingKey in formData.openings) {
          formDataToSend.append(`openings[${openingKey}]`, formData.openings[openingKey]);
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    for (const key in imagenes) {
      if (imagenes[key]) {
        formDataToSend.append(key, imagenes[key], `${key}.jpg`);
      }
    }

    try {
      const response = await axios.post(
        `https://back.ivdian.ca/api/constructoras/${formData.constructoraId}/fraccionamientos/${formData.fraccionamiento}/lotes`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        console.log('Lote agregado exitosamente');
        onAddLote(response.data);
      } else {
        console.error('Error al agregar el lote');
        onAddLote(response.data);
      }
    } catch (error) {
      console.error('Error al enviar los datos del lote', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false); // Desactivar el loading después de completar la solicitud
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-bold mb-4">Agregar Nuevo Lote</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Número de Lote:</label>
          <input
            type="text"
            name="numero_lote"
            value={formData.numero_lote}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Openings:</label>
          <div className="flex space-x-4">
            {['frente', 'derecha', 'izquierda', 'atras'].map((side) => (
              <div key={side} className="flex items-center">
                <input
                  type="text"
                  name={side}
                  placeholder={side.charAt(0).toUpperCase() + side.slice(1)}
                  value={formData.openings[side]}
                  onChange={handleOpeningsChange}
                  className="mt-1 p-2 border rounded-md w-full"
                />
              </div>
            ))}
          </div>
        </div>

        {['vinil', 'basements', 'color', 'estado'].map((field) => (
          <div key={field}>
            <label className="block text-sm font-medium text-gray-700">{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
            <input
              type="text"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="mt-1 p-2 border rounded-md w-full"
            />
          </div>
        ))}

        <div>
          <label className="block text-sm font-medium text-gray-700">Imágenes:</label>
          <div className="space-x-4">
            {['frente', 'derecha', 'izquierda', 'atras'].map((side) => (
              <div key={side} className="flex flex-col">
                <label className="mt-1">{side.charAt(0).toUpperCase() + side.slice(1)}:</label>
                <input
                  type="file"
                  name={side}
                  onChange={handleImageChange}
                  accept="image/*"
                  className="p-2 border rounded-md"
                />
              </div>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading} // Desactiva el botón si está cargando
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <FontAwesomeIcon icon={faCircleNotch} spin className="mr-2" />
              Cargando...
            </span>
          ) : (
            'Agregar Lote'
          )}
        </button>
      </form>
    </div>
  );
};

export default AgregarLoteComponent;
