import React, { useState } from 'react';
import axios from 'axios';

const AgregarIncidenciaForm = ({ loteId, fraccionamientoId }) => {
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [trabajador, setTrabajador] = useState('');
  const [openings, setOpenings] = useState('');
  const [imagen, setImagen] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('loteId', loteId); // Usar loteId directamente
    formData.append('trabajador', trabajador);
    formData.append('openings', openings);
    formData.append('fraccionamiento', fraccionamientoId);
    if (imagen) {
      formData.append('imagen', imagen);
    }
    console.log(formData);
    try {
      const response = await axios.post(`https://back.ivdian.ca/api/constructoras/{constructorId}/fraccionamientos/{fraccionamientoId}/lotes/${loteId}/incidencias`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(response.data.message);
      console.log(formData);
    } catch (error) {
      console.error('Error al agregar la incidencia:', error);
      alert('Error al agregar la incidencia');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Título:</label>
        <input
          type="text"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Descripción:</label>
        <textarea
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Trabajador:</label>
        <select value={trabajador} onChange={(e) => setTrabajador(e.target.value)} required>
          <option value="">Selecciona un trabajador</option>
          <option value="Valdo">Valdo</option>
          <option value="Victor">Victor</option>
          <option value="Jancarlo">Jancarlo</option>
          <option value="Yazmin">Yazmin</option>
          <option value="Ale">Ale</option>
          <option value="Angel">Angel</option>
          <option value="Didier">Didier</option>
          <option value="Cristian">Cristian</option>
          <option value="Juan">Juan</option>
          <option value="Jorge">Jorge</option>
          <option value="Luis">Luis</option>
          <option value="Marcos">Marcos</option>
          <option value="Ruben">Ruben</option>
          <option value="Arturo">Arturo</option>
          <option value="Ivan">Ivan</option>
        </select>
      </div>
      <div>
        <label>Aperturas:</label>
        <input
          type="text"
          value={openings}
          onChange={(e) => setOpenings(e.target.value)}
        />
      </div>
      <div>
        <label>Imagen:</label>
        <input
          type="file"
          onChange={(e) => setImagen(e.target.files[0])}
        />
      </div>
      <button type="submit">Agregar Incidencia</button>
    </form>
  );
};

export default AgregarIncidenciaForm;
