import React, { useState } from 'react';
import alertify from 'alertifyjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const ProtectedContent = ({ correctPassword, children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false); // Para controlar el modal

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
      alertify.success('Acceso concedido');
      setModalOpen(false); // Cerrar el modal
    } else {
      alertify.error('Contraseña incorrecta');
      setPassword(''); // Limpiar el campo de entrada
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {isAuthenticated ? (
        <div className="">{children} {/* Mostrar contenido protegido */}</div>
      ) : (
        <>
          {/* Botón para abrir el modal */}
          <button
            onClick={() => setModalOpen(true)}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 flex items-center"
          >
            {/* Solo el icono en móvil y icono + texto en escritorio */}
            <FontAwesomeIcon icon={faLock} className="mr-2" />
            <span className="hidden md:inline">Protegido</span> {/* Oculta en pantallas pequeñas */}
          </button>

          {/* Modal para ingresar la contraseña */}
          {modalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-md w-80">
                <h2 className="text-lg font-semibold mb-4">Ingrese la contraseña:</h2>
                <form onSubmit={handleSubmit}>
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 w-full"
                  />
                  <button
                    type="submit"
                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300 w-full"
                  >
                    Acceder
                  </button>
                  <button
                    type="button"
                    onClick={() => setModalOpen(false)} // Cerrar modal
                    className="mt-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 focus:outline-none w-full"
                  >
                    Cancelar
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProtectedContent;
