import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <AnimatePresence >
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-75 z-50" // Opacidad en el fondo
          
        >
          <div className="relative bg-white p-6 rounded-md shadow-md" >
            {/* Botón de cierre visible */}
            <button className="absolute top-2 right-2 text-xl text-red-600" onClick={onClose}>
              X
            </button>
            <div className="flex justify-center items-center">
              {children}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
