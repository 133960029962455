import React, { useState } from 'react';
import FramerButton from './FramerButton';
import AddLoteForm from './AgregarLoteComponent';

const AddLoteModal = ({ fraccionamientoId, constructoraId, onAddLote, onClose }) => { // Agrega constructoraId aquí
  console.log("Constructora ID:", constructoraId); // Verifica el valor aquí
  console.log("fraccionamiento ID:", fraccionamientoId); // Verifica el valor aquí

  const [showModal, setShowModal] = useState(true);
  const handleAddLote = (loteData) => {
    onAddLote(loteData);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  return (
    <div className={`fixed z-50 inset-0 overflow-y-auto ${showModal ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white p-4">
            <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <FramerButton>
                <div onClick={handleClose}>
                  <h5 className="text-lg text-red-500 font-black mb-4 inline-flex">X</h5>
                </div>
              </FramerButton>
            </div>
            <AddLoteForm
              fraccionamientoId={fraccionamientoId}
              constructoraId={constructoraId} // Pasa constructoraId aquí
              onAddLote={handleAddLote}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLoteModal;
